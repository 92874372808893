<template>
  <v-card class="mx-auto" :disabled="loading" :loading="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name: 'reports',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h5" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card
            class="mx-auto"
            :disabled="report_type == 3 && search.insurances != null"
          >
            <v-card-title>
              <v-row>
                <v-col cols="12" xs="12" md="8">
                  <span class="text-h6" v-text="'Filtros'" />
                </v-col>
                <v-col cols="12" xs="12" md="4" class="text-right"> </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <v-autocomplete
                    label="Tipo*"
                    v-model="report_type"
                    :items="report_types"
                    item-value="id"
                    :item-text="(v) => v.name"
                    dense
                    @change="reportType"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="filter.benefits_provider_id"
                >
                  <v-autocomplete
                    label="Proveedor"
                    v-model="search.benefits_provider_id"
                    :items="benefits_providers"
                    item-value="id"
                    :item-text="(item) => item.name"
                    dense
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="filter.insurance_id">
                  <v-autocomplete
                    label="Aseguradora*"
                    v-model="search.insurance_id"
                    :items="insurances"
                    item-value="id"
                    :item-text="(v) => v.name"
                    :loading="insurances_loading"
                    dense
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="filter.insurances_id">
                  <v-autocomplete
                    label="Aseguradora(s)*"
                    v-model="search.insurances_id"
                    :items="insurances_multi"
                    item-value="id"
                    :item-text="(v) => v.name"
                    :loading="insurances_multi_loading"
                    dense
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  class="text-right"
                  v-if="filter.start_date"
                >
                  <DatePicker
                    :label="`Fecha inicio de ${
                      report_type == 1
                        ? 'ingresos'
                        : report_type == 4 ||
                          report_type == 5 ||
                          report_type == 6
                        ? 'recepción'
                        : 'cartas'
                    }`"
                    :model.sync="search.start_date"
                    all_date
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  class="text-right"
                  v-if="filter.end_date"
                >
                  <DatePicker
                    :label="`Fecha final de ${
                      report_type == 1
                        ? 'ingresos'
                        : report_type == 4 ||
                          report_type == 5 ||
                          report_type == 6
                        ? 'recepción'
                        : 'cartas'
                    }`"
                    :model.sync="search.end_date"
                    all_date
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="filter.contractors_brands"
                >
                  <v-autocomplete
                    label="Concentrador contratantes*"
                    v-model="search.contractors_brands"
                    :items="contractors_brands"
                    item-value="id"
                    :item-text="(v) => v.contractor_brand"
                    dense
                    multiple
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="filter.contractor_brand_id"
                >
                  <v-autocomplete
                    label="Concentrador contratantes*"
                    v-model="search.contractor_brand_id"
                    :items="contractors_brands"
                    item-value="id"
                    :item-text="(v) => v.contractor_brand"
                    dense
                  />
                </v-col>
                <v-col cols="12" v-if="filter.cpt_id">
                  <v-autocomplete
                    label="CPT(s) se toma en cuenta todos si no se selecciona ninguno"
                    v-model="search.cpt_id"
                    :items="cpts"
                    item-value="id"
                    :item-text="(v) => `${v.code} | ${v.cpt}`"
                    :loading="cpts_loading"
                    dense
                    multiple
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="report_type == 3 && !search.insurances">
          <v-btn
            block
            small
            :loading="providers_entities_loading"
            :disabled="
              providers_entities_loading || search.insurances_id.length == 0
            "
            color="primary"
            @click.prevent="ProvidersEntitiesData"
          >
            Continuar
            <v-icon right v-text="'mdi-chevron-right'" />
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="report_type == 3 && search.insurances != null">
          <v-btn block small @click.prevent="ProvidersEntitiesBack">
            <v-icon left v-text="'mdi-chevron-left'" />
            Átras
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="report_type == 3 && search.insurances != null">
          <v-row>
            <v-col
              v-for="(insurance, i) in search.insurances"
              :key="i"
              cols="12"
            >
              <v-card class="mx-auto">
                <v-card-title>
                  <v-row>
                    <v-col cols="12" xs="12" md="8">
                      <span class="text-h6" v-text="insurance.insurance" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4" class="text-right"> </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-autocomplete
                        label="Hospital(es) se toma en cuenta todos si no se selecciona ninguno"
                        v-model="insurance.providers"
                        :items="insurance.providers_select"
                        item-value="provider_id"
                        :item-text="(v) => v.trade_name"
                        dense
                        multiple
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        label="Estado(s) se toma en cuenta todos si no se selecciona ninguno"
                        v-model="insurance.entities"
                        :items="insurance.entities_select"
                        item-value="entity_id"
                        :item-text="(v) => v.entity"
                        dense
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" v-if="report_type">
          <v-btn
            block
            small
            :loading="loading"
            :disabled="
              loading ||
              (report_type == 2 && !search.benefits_provider_id) ||
              (report_type == 3 && !search.insurances) ||
              ((report_type == 4 || report_type == 5) &&
                !search.insurances_id.length > 0) ||
              (report_type == 6 &&
                !search.insurances &&
                !search.contractor_brand_id)
            "
            color="success"
            @click.prevent="reportData"
          >
            Buscar
            <v-icon right v-text="'mdi-magnify'" />
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="data && data.general">
        <v-col cols="12" xs="12" md="6">
          <span class="text-h6" v-text="'General'" />
        </v-col>
        <v-col cols="12" v-if="report_type == 1">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Cobertura</th>
                  <th>Cantidad</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, i) in data.general.list" :key="i">
                  <td v-text="i + 1" />
                  <td v-text="v.coverage.coverage_type" />
                  <td v-text="v.quantity" />
                  <td v-text="numberFormat(v.total_amount)" />
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td
                    class="font-weight-bold"
                    v-text="data.general.total_quantity"
                  />
                  <td
                    class="font-weight-bold"
                    v-text="numberFormat(data.general.total_amount)"
                  />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" v-if="report_type == 3">
          <v-row dense>
            <v-col cols="12" v-for="(insurance, i) in data.general" :key="i">
              <v-card>
                <v-card-title>
                  <v-row>
                    <v-col cols="12" xs="12" md="8">
                      <span class="text-h6" v-text="insurance.name" />
                    </v-col>
                    <v-col cols="12" xs="12" md="4" class="text-right"> </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <h4 v-text="'Hospitales'" />
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Cantidad</th>
                              <th>Total</th>
                              <th>Porc. Cantidad</th>
                              <th>Porc. Total</th>
                              <th>Promedio</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(provider, j) in insurance.providers"
                              :key="j"
                            >
                              <td v-text="j + 1" />
                              <td v-text="provider.trade_name" />
                              <td v-text="provider.quantity" />
                              <td v-text="provider.amount" />
                              <td v-text="provider.quantity_percent" />
                              <td v-text="provider.amount_percent" />
                              <td v-text="provider.amount_avg" />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      text
                                      icon
                                      x-small
                                      color="info"
                                      @click.prevent="
                                        InsuranceProvider(
                                          true,
                                          insurance.id,
                                          provider.id
                                        )
                                      "
                                    >
                                      <v-icon
                                        medium
                                        v-text="'mdi-hospital-building'"
                                      />
                                    </v-btn>
                                  </template>
                                  <span v-text="'CPT(s)'" />
                                </v-tooltip>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      text
                                      icon
                                      x-small
                                      class="ml-1"
                                      color="warning"
                                      @click.prevent="
                                        InsuranceProvider(
                                          false,
                                          insurance.id,
                                          provider.id
                                        )
                                      "
                                    >
                                      <v-icon medium v-text="'mdi-doctor'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Médico(s)'" />
                                </v-tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="insurance.providers_quantity"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="insurance.providers_amount"
                              />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                      <h4 v-text="'Entidades'" />
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nombre</th>
                              <th>Cantidad</th>
                              <th>Total</th>
                              <th>Porc. Cantidad</th>
                              <th>Porc. Total</th>
                              <th>Promedio</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(entity, k) in insurance.entities"
                              :key="k"
                            >
                              <td v-text="k + 1" />
                              <td v-text="entity.entity" />
                              <td v-text="entity.quantity" />
                              <td v-text="entity.amount" />
                              <td v-text="entity.quantity_percent" />
                              <td v-text="entity.amount_percent" />
                              <td v-text="entity.amount_avg" />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td
                                class="font-weight-bold"
                                v-text="insurance.entities_quantity"
                              />
                              <td
                                class="font-weight-bold"
                                v-text="insurance.entities_amount"
                              />
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="data && data.list">
        <v-col cols="12" xs="12" md="6">
          <span class="text-h6" v-text="`Detalle (${data.list.length})`" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-text-field
            v-model="table_search"
            append-icon="mdi-magnify"
            label="Buscar en tabla..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          v-if="report_type == 1 || report_type == 2 || report_type == 3"
        >
          <v-btn block small color="" @click.prevent="copy">
            Copiar contenido
            <v-icon right v-text="'mdi-content-copy'" />
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          v-if="report_type == 4 || report_type == 5 || report_type == 6"
        >
          <v-btn block small color="warning" @click.prevent="downloadFile">
            Excel
            <v-icon right v-text="'mdi-file'" />
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="report_type == 1">
          <v-data-table
            item-key="rha_account_exception_id"
            :search="table_search"
            :items-per-page="15"
            :headers="headers_coverages"
            :loading="loading"
            :items="data.list"
            dense
            id="table"
          >
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="report_type == 2">
          <v-data-table
            item-key="id"
            :search="table_search"
            :items-per-page="15"
            :headers="headers_benefits"
            :loading="loading"
            :items="data.list"
            dense
            id="table"
          >
            <template v-slot:item.benefit_details.amount="{ item }">
              {{ numberFormat(item.benefit_details.amount) }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="report_type == 4">
          <v-data-table
            item-key="rha_bill_id"
            :search="table_search"
            :items-per-page="15"
            :headers="headers_report_12"
            :loading="loading"
            :items="data.list"
            dense
            id="table"
          >
            <template v-slot:item.insurance_pay_amount="{ item }">
              {{ numberFormat(item.insurance_pay_amount) }}
            </template>
            <template v-slot:item.difference="{ item }">
              {{ numberFormat(item.difference) }}
            </template>
            <template v-slot:item.coinsurances_amount="{ item }">
              {{ numberFormat(item.coinsurances_amount) }}
            </template>
            <template v-slot:item.amount_deductible="{ item }">
              {{ numberFormat(item.amount_deductible) }}
            </template>
            <template v-slot:item.doctors_amount="{ item }">
              {{ numberFormat(item.doctors_amount) }}
            </template>
            <template v-slot:item.discount_transferred_amount="{ item }">
              {{
                item.discount_transferred_amount
                  ? numberFormat(item.discount_transferred_amount)
                  : "-"
              }}
            </template>
            <template v-slot:item.action="{ item, index }">
              <v-tooltip
                v-if="!item.last_rha_letter_id && !item.express_letter"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    dark
                    x-small
                    color="warning"
                    :to="{
                      name: 'admission_request.letters',
                      params: { id: item.rha_id },
                    }"
                    target="_blank"
                  >
                    <v-icon> mdi-file-certificate </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Generar carta definitiva'" />
              </v-tooltip>
              <v-tooltip v-if="item.express_letter" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    dark
                    x-small
                    color="error"
                    @click.prevent="expressLetterDialog(item, index)"
                  >
                    <v-icon> mdi-file </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Datos de carta exprés'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="report_type == 5">
          <v-data-table
            item-key="rha_bill_id"
            :search="table_search"
            :items-per-page="15"
            :headers="headers_report_12_payments"
            :loading="loading"
            :items="data.list"
            dense
            id="table"
          >
          </v-data-table>
        </v-col>
        <v-col cols="12" v-if="report_type == 6">
          <v-data-table
            item-key="rs_service_bill_id"
            :search="table_search"
            :items-per-page="15"
            :headers="headers_report_12"
            :loading="loading"
            :items="data.list"
            dense
            id="table"
          >
            <template v-slot:item.difference="{ item }">
              {{ numberFormat(item.difference) }}
            </template>
            <template v-slot:item.coinsurances_amount="{ item }">
              {{ numberFormat(item.coinsurances_amount) }}
            </template>
            <template v-slot:item.amount_deductible="{ item }">
              {{ numberFormat(item.amount_deductible) }}
            </template>
            <template v-slot:item.doctors_amount="{ item }">
              {{ numberFormat(item.doctors_amount) }}
            </template>
            <template v-slot:item.discount_transferred_amount="{ item }">
              {{ numberFormat(item.discount_transferred_amount) }}
            </template>
            <template v-slot:item.xml_subtotal_amount="{ item }">
              {{
                item.xml_subtotal_amount
                  ? numberFormat(item.xml_subtotal_amount)
                  : "-"
              }}
            </template>
            <template v-slot:item.xml_isr_amount="{ item }">
              {{
                item.xml_isr_amount ? numberFormat(item.xml_isr_amount) : "-"
              }}
            </template>
            <template v-slot:item.xml_iva_amount="{ item }">
              {{
                item.xml_iva_amount ? numberFormat(item.xml_iva_amount) : "-"
              }}
            </template>
            <template v-slot:item.xml_total_amount="{ item }">
              {{
                item.xml_total_amount
                  ? numberFormat(item.xml_total_amount)
                  : "-"
              }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    dark
                    x-small
                    :color="
                      item.accident == null && item.initial == null
                        ? 'grey darken-2'
                        : 'pink darken-2'
                    "
                    :to="{
                      name: 'servicios.seguimiento',
                      params: { id: item.rs_id },
                    }"
                    target="_blank"
                  >
                    <v-icon> mdi-medical-bag </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Seg. Médico'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="insurance_provider_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="insurance_provider_dialog_loading"
        :loading="insurance_provider_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`${report_cpt ? 'CPT' : 'Médico'}(s)`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_provider_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_provider">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th>Porc. Cantidad</th>
                      <th>Porc. Total</th>
                      <th>Promedio</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in insurance_provider.items" :key="i">
                      <td v-text="i + 1" />
                      <td
                        v-text="
                          report_cpt
                            ? `${item.code} | ${item.cpt}`
                            : item.full_name
                        "
                      />
                      <td v-text="item.quantity" />
                      <td v-text="item.amount" />
                      <td v-text="item.quantity_percent" />
                      <td v-text="item.amount_percent" />
                      <td v-text="item.amount_avg" />
                      <td>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              :color="report_cpt ? 'warning' : 'info'"
                              @click.prevent="
                                InsuranceProviderCptDoctor(
                                  report_cpt ? item.id : null,
                                  report_cpt ? null : item.doctor_name,
                                  report_cpt ? null : item.doctor_first_surname,
                                  report_cpt ? null : item.doctor_second_surname
                                )
                              "
                            >
                              <v-icon
                                medium
                                v-text="
                                  `mdi-${
                                    report_cpt ? 'doctor' : 'hospital-building'
                                  }`
                                "
                              />
                            </v-btn>
                          </template>
                          <span
                            v-text="`${report_cpt ? 'Médico' : 'CPT'}(s)`"
                          />
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td
                        class="font-weight-bold"
                        v-text="insurance_provider.quantity"
                      />
                      <td
                        class="font-weight-bold"
                        v-text="insurance_provider.amount"
                      />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insurance_provider_cpt_doctor_dialog"
      scrollable
      persistent
      max-width="1200px"
    >
      <v-card
        tile
        :disabled="insurance_provider_cpt_doctor_dialog_loading"
        :loading="insurance_provider_cpt_doctor_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="`${report_cpt ? 'Médico' : 'CPT'}(s)`" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="insurance_provider_cpt_doctor_dialog = false"
            >
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_provider_cpt_doctor">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th>Porc. Cantidad</th>
                      <th>Porc. Total</th>
                      <th>Promedio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in insurance_provider_cpt_doctor.items"
                      :key="i"
                    >
                      <td v-text="i + 1" />
                      <td
                        v-text="
                          report_cpt
                            ? item.full_name
                              ? item.full_name
                              : '*NO REGISTRADO'
                            : item.cpt
                        "
                      />
                      <td v-text="item.quantity" />
                      <td v-text="item.amount" />
                      <td v-text="item.quantity_percent" />
                      <td v-text="item.amount_percent" />
                      <td v-text="item.amount_avg" />
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td
                        class="font-weight-bold"
                        v-text="insurance_provider_cpt_doctor.quantity"
                      />
                      <td
                        class="font-weight-bold"
                        v-text="insurance_provider_cpt_doctor.amount"
                      />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="express_letter_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="express_letter_dialog_loading"
        :loading="express_letter_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title>
            FOLIO {{ express_letter ? express_letter.rha_folio : "" }} | CARTA
            EXPRÉS
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click.prevent="express_letter_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="express_letter">
          <v-row>
            <v-col cols="12" />
            <v-col v-if="express_letter.store" cols="12">
              <v-form ref="express_letter_form" lazy-validation>
                <v-row dense>
                  <v-col cols="12" xs="12" md="6">
                    <v-radio-group
                      class="mt-0"
                      v-model="express_letter.express_accident"
                      :rules="rules.requiredNotNull"
                      row
                    >
                      <v-radio label="Accidente" :value="true" />
                      <v-radio label="Enfermedad" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-radio-group
                      class="mt-0"
                      v-model="express_letter.express_initial"
                      :rules="rules.requiredNotNull"
                      row
                    >
                      <v-radio label="Inicial" :value="true" />
                      <v-radio label="Complemento" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-card tile>
                      <v-toolbar elevation="1" dense>
                        <v-toolbar-title class="text-caption">
                          ICD(S)
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-simple-table dense>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th v-text="'#'" />
                                    <th v-text="'Código'" />
                                    <th v-text="'Descripción'" />
                                    <th v-text="''" />
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(v, i) in express_letter.icds"
                                    :key="i"
                                    v-if="v.active"
                                  >
                                    <td v-text="`${i + 1}`" />
                                    <td v-text="v.code" />
                                    <td v-text="v.icd" />
                                    <td>
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <v-btn
                                            v-on="on"
                                            text
                                            icon
                                            x-small
                                            color="error"
                                            @click="icdRemove(i)"
                                          >
                                            <v-icon medium> mdi-close </v-icon>
                                          </v-btn>
                                        </template>
                                        <span
                                          v-text="`Eliminar ICD (${i + 1})`"
                                        />
                                      </v-tooltip>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-col>
                          <v-col cols="12" class="mb-5">
                            <hr />
                          </v-col>
                          <v-col cols="11" class="mt-4">
                            <v-autocomplete
                              label="Descripción"
                              v-model="icd"
                              :items="icds"
                              :item-text="
                                (item) => `${item.code} | ${item.icd}`
                              "
                              :loading="icds_loading"
                              return-object
                              dense
                            />
                          </v-col>
                          <v-col cols="1" class="mt-4">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  dark
                                  small
                                  block
                                  color="orange darken-2"
                                  @click="icdAdd"
                                >
                                  <v-icon> mdi-plus </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Agregar'" />
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      small
                      color="info"
                      @click.prevent="expressLetterHandle"
                    >
                      GUARDAR <v-icon small right> mdi-send </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  msgAlert,
  msgConfirm,
  rules,
} from "../../control";
import { index } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      rules: rules(),
      login: this.$store.getters.getLogin,
      loading: false,
      report_type: null,
      report_types: [
        {
          id: 1,
          name: "COBERTURAS",
        },
        {
          id: 2,
          name: "PRESTACIONES AÑADIDAS",
        },
        {
          id: 3,
          name: "INGRESOS",
        },
        {
          id: 4,
          name: "INGRESOS LÍNEA 12",
        },
        {
          id: 5,
          name: "INGRESOS LÍNEA 12 | PAGOS",
        },
        {
          id: 6,
          name: "SERVICIOS LÍNEA 12",
        },
      ],
      insurances: [],
      insurances_loading: true,
      insurances_multi: [],
      insurances_multi_loading: true,
      cpts: [],
      cpts_loading: true,
      providers_entities_loading: false,
      providers: [],
      entities: [],
      search: {
        insurance_id: null,
        insurances_id: [],
        start_date: "",
        end_date: "",
        cpt_id: [],
        insurances: null,
        benefits_provider_id: null,
        contractors_brands: [],
        contractor_brand_id: null,
      },
      filter: {
        insurance_id: false,
        insurances_id: false,
        start_date: false,
        end_date: false,
        cpt_id: false,
        benefits_provider_id: false,
        contractors_brands: false,
        contractor_brand_id: false,
      },
      data: null,
      table_search: "",
      headers_report_12: [
        {
          text: "Aseguradora",
          filterable: true,
          value: "insurance",
        },
        {
          text: "ID Asegurado",
          filterable: true,
          value: "enrollment",
        },
        {
          text: "Paciente",
          filterable: true,
          value: "full_name",
        },
        {
          text: "Fecha Nacimiento",
          filterable: true,
          value: "birthday",
        },
        {
          text: "Hospital",
          filterable: true,
          value: "trade_name",
        },
        {
          text: "IP/OP",
          filterable: true,
          value: "ip_op",
        },
        {
          text: "Fecha Ingreso",
          filterable: true,
          value: "admission_date",
        },
        {
          text: "Fecha Alta",
          filterable: true,
          value: "discharge",
        },
        {
          text: "Diagnostico",
          filterable: true,
          value: "icd",
        },
        {
          text: "Descripcion",
          filterable: true,
          value: "description",
        },
        {
          text: "ID NET",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "Remesa",
          filterable: true,
          value: "consignment",
        },
        {
          text: "Folio SM",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "Fecha Envio",
          filterable: true,
          value: "insurance_reception_date",
        },
        {
          text: "Total Factura",
          filterable: true,
          value: "insurance_pay_amount",
        },
        {
          text: "Diferencia",
          filterable: true,
          value: "difference",
        },
        {
          text: "ID",
          filterable: true,
          value: "rha_ident",
        },
        {
          text: "Contratante",
          filterable: true,
          value: "contractor_name",
        },
        {
          text: "Coaseguro",
          filterable: true,
          value: "coinsurances_amount",
        },
        {
          text: "Deducible",
          filterable: true,
          value: "amount_deductible",
        },
        {
          text: "ID Misionero",
          filterable: true,
          value: "credential",
        },
        {
          text: "Mision",
          filterable: true,
          value: "office_address",
        },
        {
          text: "Honorarios Medicos",
          filterable: true,
          value: "doctors_amount",
        },
        {
          text: "Desglose HM",
          filterable: true,
          value: "doctors",
        },
        {
          text: "Coaseguro Porcentaje",
          filterable: true,
          value: "coinsurance_doctors_amount",
        },
        {
          text: "Fecha Carta",
          filterable: true,
          value: "letter_date",
        },
        {
          text: "Paciente Nombre",
          filterable: true,
          value: "name",
        },
        {
          text: "Paciente A Paterno",
          filterable: true,
          value: "first_surname",
        },
        {
          text: "Paciente A Materno",
          filterable: true,
          value: "second_surname",
        },
        {
          text: "Desc. tras. prop.",
          filterable: true,
          value: "discount_transferred_amount",
        },
        {
          text: "Folio Fact.",
          filterable: true,
          value: "xml_folio",
        },
        {
          text: "Régimen F.",
          filterable: true,
          value: "fiscal_regime_code",
        },
        {
          text: "Subtotal",
          filterable: true,
          value: "xml_subtotal_amount",
        },
        {
          text: "ISR",
          filterable: true,
          value: "xml_isr_amount",
        },
        {
          text: "IVA",
          filterable: true,
          value: "xml_iva_amount",
        },
        {
          text: "Total",
          filterable: true,
          value: "xml_total_amount",
        },
        {
          text: "Padecimiento",
          filterable: true,
          value: "accident",
        },
        {
          text: "Siniestro",
          filterable: true,
          value: "initial",
        },
        {
          text: "",
          filterable: false,
          value: "action",
        },
      ],
      headers_report_12_payments: [
        {
          text: "F. Pago Hosp.",
          filterable: true,
          value: "pay_date",
        },
        {
          text: "ID",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "Aseguradora",
          filterable: true,
          value: "insurance",
        },
        {
          text: "ID Asegurado",
          filterable: true,
          value: "enrollment",
        },
        {
          text: "Paciente",
          filterable: true,
          value: "full_name",
        },
        {
          text: "ID NET",
          filterable: true,
          value: "rha_folio",
        },
        {
          text: "Remesa",
          filterable: true,
          value: "consignment",
        },
        {
          text: "Folio SM",
          filterable: true,
          value: "rha_bill_folio",
        },
        {
          text: "Desglose HM",
          filterable: true,
          value: "doctors",
        },
      ],
      headers_coverages: [
        {
          text: "Aseguradora",
          filterable: true,
          value: "insurance",
        },
        {
          text: "Folio",
          filterable: true,
          value: "folio",
        },
        {
          text: "F. admisión",
          filterable: true,
          value: "admission_date",
        },
        {
          text: "Hospital",
          filterable: true,
          value: "provider",
        },
        {
          text: "N. siniestro",
          filterable: true,
          value: "sinister_number",
        },
        {
          text: "Fecha",
          filterable: true,
          value: "fecha_monto",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "benefit_type",
        },
        {
          text: "Código",
          filterable: true,
          value: "code",
        },
        {
          text: "Descripción",
          filterable: true,
          value: "description",
        },
        {
          text: "Precio",
          filterable: true,
          value: "amount",
        },
        {
          text: "Cantidad",
          filterable: true,
          value: "quantity",
        },
        {
          text: "Cobertura",
          filterable: true,
          value: "coverage.coverage_type",
        },
      ],
      headers_benefits: [
        {
          text: "Fecha",
          filterable: true,
          value: "created_at",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "benefit_type.benefit_type",
        },
        {
          text: "Código",
          filterable: true,
          value: "code",
        },
        {
          text: "Nombre",
          filterable: true,
          value: "benefit_details.description",
        },
        {
          text: "Monto",
          filterable: true,
          value: "benefit_details.amount",
        },
      ],
      report_cpt: null,
      insurance_provider: null,
      insurance_provider_dialog: false,
      insurance_provider_dialog_loading: false,
      insurance_provider_cpt_doctor: null,
      insurance_provider_cpt_doctor_dialog: false,
      insurance_provider_cpt_doctor_dialog_loading: false,
      insurance_provider_doctor: null,
      insurance_provider_doctor_dialog: false,
      insurance_provider_doctor_dialog_loading: false,
      insurance_id: null,
      provider_id: null,
      benefits_providers: [],
      contractors_brands: [],
      index: null,
      express_letter: null,
      express_letter_dialog: false,
      express_letter_dialog_loading: false,
      icd: null,
      icds: [],
      icds_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    reportType() {
      this.data = null;
      this.table_search = "";
      this.search.insurances = null;

      switch (this.report_type) {
        case 1:
          this.filter.insurance_id = true;
          this.filter.insurances_id = false;
          this.filter.start_date = true;
          this.filter.end_date = true;
          this.filter.cpt_id = false;
          this.filter.benefits_provider_id = false;
          this.filter.contractors_brands = false;
          this.filter.contractor_brand_id = false;
          break;
        case 2:
          this.filter.insurance_id = false;
          this.filter.insurances_id = false;
          this.filter.start_date = false;
          this.filter.end_date = false;
          this.filter.cpt_id = false;
          this.filter.benefits_provider_id = true;
          this.filter.contractors_brands = false;
          this.filter.contractor_brand_id = false;
          break;
        case 3:
          this.filter.insurance_id = false;
          this.filter.insurances_id = true;
          this.filter.start_date = true;
          this.filter.end_date = true;
          this.filter.cpt_id = true;
          this.filter.benefits_provider_id = false;
          this.filter.contractors_brands = false;
          this.filter.contractor_brand_id = false;
          break;
        case 4:
          this.filter.insurance_id = false;
          this.filter.insurances_id = true;
          this.filter.start_date = true;
          this.filter.end_date = true;
          this.filter.cpt_id = false;
          this.filter.benefits_provider_id = false;
          this.filter.contractors_brands = true;
          this.filter.contractor_brand_id = false;
          break;
        case 5:
          this.filter.insurance_id = false;
          this.filter.insurances_id = true;
          this.filter.start_date = true;
          this.filter.end_date = true;
          this.filter.cpt_id = false;
          this.filter.benefits_provider_id = false;
          this.filter.contractors_brands = false;
          this.filter.contractor_brand_id = false;
          break;
        case 6:
          this.filter.insurance_id = false;
          this.filter.insurances_id = true;
          this.filter.start_date = true;
          this.filter.end_date = true;
          this.filter.cpt_id = false;
          this.filter.benefits_provider_id = false;
          this.filter.contractors_brands = false;
          this.filter.contractor_brand_id = true;
          break;
      }
    },
    ProvidersEntitiesData() {
      this.providers_entities_loading = true;
      this.search.insurances = null;

      Axios.post(
        URL_API + "/rha/report/insurances/provides/entities",
        {
          insurances: this.search.insurances_id,
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          cpt_id: this.search.cpt_id.length > 0 ? this.search.cpt_id : null,
        },
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data);
        if (response.data.success) {
          this.search.insurances = response.data.data;
          for (let insurance of this.search.insurances) {
            insurance.providers_select = insurance.providers;
            insurance.entities_select = insurance.entities;
            insurance.providers = [];
            insurance.entities = [];
          }
          this.providers_entities_loading = false;
        } else {
          console.log(response.data.message);
        }
      });
    },
    ProvidersEntitiesBack() {
      this.search.insurances = null;
      this.data = null;
    },
    reportData() {
      this.data = null;
      this.table_search = "";
      this.loading = true;
      let api = URL_API + "/";
      let res = null;

      switch (this.report_type) {
        case 1:
          api += "rha/report/exceptions";
          res = {
            insurance_id: this.search.insurance_id,
            start_date: this.search.start_date,
            end_date: this.search.end_date,
          };
          break;
        case 2:
          api +=
            "rha/report/added/benefits/" + this.search.benefits_provider_id;
          break;
        case 3:
          api += "rha/report/insurances/amounts";
          res = {
            insurances: [],
            start_date: this.search.start_date,
            end_date: this.search.end_date,
            cpt_id: this.search.cpt_id.length > 0 ? this.search.cpt_id : null,
          };

          for (let insurance of this.search.insurances) {
            let insurance_res = {
              id: insurance.id,
              insurance: insurance.insurance,
              providers:
                insurance.providers.length > 0 ? insurance.providers : null,
              entities:
                insurance.entities.length > 0 ? insurance.entities : null,
            };

            res.insurances.push(insurance_res);
          }
          break;
        case 4:
          api += "rha/report/insurances/insured";
          res = {
            insurances: this.search.insurances_id,
            start_date: this.search.start_date,
            end_date: this.search.end_date,
            contractors_brands: this.search.contractors_brands,
          };
          break;
        case 5:
          api += "rha/report/insurances/insured/payment";
          res = {
            insurances: this.search.insurances_id,
            start_date: this.search.start_date,
            end_date: this.search.end_date,
          };
          break;
        case 6:
          api += "rss/services/bills/report/12";
          res = {
            insurances: this.search.insurances_id,
            start_date: this.search.start_date,
            end_date: this.search.end_date,
            contractor_brand_id: this.search.contractor_brand_id,
          };
          break;
      }

      //console.log(res);

      if (this.report_type !== 2) {
        Axios.post(api, res, headersToken(this.login.token)).then(
          (response) => {
            // console.log(response.data);
            if (response.data.success) {
              this.data = response.data.data;
              this.loading = false;
            } else {
              console.log(response.data.message);
            }
          }
        );
      } else if (this.report_type == 2) {
        Axios.get(api, headersToken(this.login.token)).then((response) => {
          //console.log(response.data);
          if (response.data.success) {
            this.data = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.message);
          }
        });
      }
    },
    InsuranceProvider(report_cpt, insurance_id, provider_id) {
      this.report_cpt = report_cpt;

      this.insurance_provider = null;
      this.insurance_provider_dialog = true;
      this.insurance_provider_dialog_loading = false;
      this.insurance_id = insurance_id;
      this.provider_id = provider_id;

      const api =
        URL_API +
        "/rha/report/insurances/amounts/" +
        (this.report_cpt ? "cpts" : "doctors");

      console.log(api);

      Axios.post(
        api,
        {
          insurance_id: this.insurance_id,
          provider_id: this.provider_id,
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          cpt_id: this.search.cpt_id.length > 0 ? this.search.cpt_id : null,
          report_type: 1,
        },
        headersToken(this.login.token)
      ).then((response) => {
        console.log(response.data);
        if (response.data.success) {
          this.insurance_provider = response.data.data.general;
          this.insurance_provider_dialog_loading = false;
        } else {
          console.log(response.data.message);
        }
      });
    },
    InsuranceProviderCptDoctor(
      cpt_id,
      doctor_name,
      doctor_first_surname,
      doctor_second_surname
    ) {
      this.insurance_provider_cpt_doctor = null;
      this.insurance_provider_cpt_doctor_dialog = true;
      this.insurance_provider_cpt_doctor_dialog_loading = false;

      const api =
        URL_API +
        "/rha/report/insurances/amounts/" +
        (this.report_cpt ? "cpts/doctors" : "doctors/cpts");

      console.log(api);

      Axios.post(
        api,
        {
          insurance_id: this.insurance_id,
          provider_id: this.provider_id,
          start_date: this.search.start_date,
          end_date: this.search.end_date,
          report_type: 1,
          cpt_id: cpt_id,
          doctor_name: doctor_name,
          doctor_first_surname: doctor_first_surname,
          doctor_second_surname: doctor_second_surname,
        },
        headersToken(this.login.token)
      ).then((response) => {
        console.log(response.data);
        if (response.data.success) {
          this.insurance_provider_cpt_doctor = response.data.data.general;
          this.insurance_provider_cpt_doctor_dialog_loading = false;
        } else {
          console.log(response.data.message);
        }
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    copy() {
      var codigoACopiar = document.getElementById("table");
      var seleccion = document.createRange();
      seleccion.selectNodeContents(codigoACopiar);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(seleccion);
      var res = document.execCommand("copy");
      window.getSelection().removeRange(seleccion);

      this.$swal.fire(msgAlert("success", "Tabla copiada en portapapeles"));
    },
    downloadFile() {
      this.loading = true;
      let api = URL_API + "/";
      let name = "";

      switch (this.report_type) {
        case 4:
          api += "rha/report/insurances/insured/excel";
          break;
        case 5:
          api += "rha/report/insurances/insured/payment/excel";
          name = "_pagos";
          break;
        case 6:
          api += "rss/services/bills/report/12/xls";
          break;
      }

      Axios.post(api, this.data, headersToken(this.login.token)).then(
        (response) => {
          //console.log(response.data);
          this.$swal.fire(
            msgAlert(
              response.data.success ? "success" : "error",
              response.data.message
            )
          );

          if (response.data.success) {
            const linkSource = `data:application/xlsx;base64,${response.data.data}`;
            const downloadLink = document.createElement("a");
            const fileName = `sm_linea_12${name}_${response.data.now}.xlsx`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          } else {
            console.log(response.data.message);
          }
          this.loading = false;
        }
      );
    },
    expressLetterDialog(item, index) {
      this.index = index;
      this.express_letter = {
        rha_folio: item.rha_folio,
        id: item.rha_id,
        express_accident:
          item.express_accident == null ? true : item.express_accident,
        express_initial:
          item.express_initial == null ? true : item.express_initial,
        store: item.express_accident == null,
        icds: [],
      };
      this.express_letter_dialog_loading = false;
      this.express_letter_dialog = true;
    },
    expressLetterHandle() {
      if (
        this.$refs.express_letter_form.validate() &&
        this.express_letter.icds.length > 0
      ) {
        this.$swal
          .fire(msgConfirm(`¿Confirma guardar información?`))
          .then((res) => {
            if (res.isConfirmed) {
              this.express_letter_dialog_loading = true;

              Axios.post(
                URL_API + "/rha/express/letter",
                this.express_letter,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  this.data.list[this.index].express_accident =
                    this.express_letter.express_accident;
                  this.data.list[this.index].express_initial =
                    this.express_letter.express_initial;
                  this.data.list[this.index].accident = res.data.accident;
                  this.data.list[this.index].initial = res.data.initial;
                  this.data.list[this.index].icd = res.data.icd;
                  this.data.list[this.index].express_letter = false;
                  this.express_letter_dialog = false;
                } else {
                  console.log(res.data.message);
                }

                this.express_letter_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados y agrega mínimo un ICD"
          )
        );
      }
    },
    icdAdd() {
      this.icd_loading = true;

      if (this.icd) {
        let icd_exists = false;

        for (let i = 0; i < this.express_letter.icds.length; i++) {
          if (
            this.express_letter.icds[i].icd_id == this.icd.id &&
            this.express_letter.icds[i].active
          ) {
            icd_exists = true;
          }
        }

        if (!icd_exists) {
          this.express_letter.icds.push({
            id: null,
            icd_id: this.icd.id,
            code: this.icd.code,
            icd: this.icd.icd,
            active: true,
          });
        } else {
          this.$swal.fire(
            msgAlert("warning", "El ICD seleccionado ya ha sido agregado")
          );
        }
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona una descripción de ICD"));
      }

      this.icd_loading = false;
    },
    icdRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar ICD (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.express_letter.icds[i].id !== null) {
              this.express_letter.icds[i].active = false;
            } else {
              this.express_letter.icds.splice(i, 1);
            }
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    if (this.login.role_id === 6) {
      index("user/" + this.login.id + "/insurances", this.login.token, []).then(
        (response) => {
          this.insurances = response.data;

          if (this.insurances.length == 1) {
            this.search.insurance_id = this.insurances[0].id;
          }

          this.insurances_loading = false;
        }
      );
      index("user/" + this.login.id + "/providers", this.login.token, []).then(
        (response) => {
          this.benefits_providers = response.data;

          if (this.benefits_providers.length == 1) {
            this.search.benefits_provider_id = this.benefits_providers[0].id;
          }
        }
      );
    } else {
      index("insurances", this.login.token, []).then((response) => {
        this.insurances = response.data;
        this.insurances_multi = response.data;
        this.insurances.push({
          id: null,
          name: "TODAS",
        });

        this.insurances_loading = false;
        this.insurances_multi_loading = false;
      });

      catalog("cpts", this.login.token).then((response) => {
        this.cpts = response;
        this.cpts_loading = false;
      });

      catalog("icds", this.login.token).then((res) => {
        this.icds = res;
        this.icds_loading = false;
      });

      index("providers/type/4", this.login.token, []).then((response) => {
        this.benefits_providers = response.data;
      });

      index("contractors/brands", this.login.token, []).then((response) => {
        this.contractors_brands = response.data;
      });
    }

    this.search.start_date = dateTimeNow().substring(0, 10);
    this.search.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>